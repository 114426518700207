import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Active Directory Assessment",
      desc: "Evaluamos la estructura y seguridad de tu directorio activo, identificando vulnerabilidades y ofreciendo recomendaciones para proteger tu infraestructura y acceso a sistemas.",
      icon: "fas fa-users-cog",
    },
    {
      name: "Application Security Verification:",
      desc: "Consiste en realizar una evaluación profunda de seguridad de aplicaciones web basada en OWASP. Se identifican las vulnerabilidades, amenazas y debilidades a las que se encuentra expuesta las aplicaciones web.",
      icon: "fas fa-shield-alt",
    },
    {
      name: "Attack Surface Management (ASM)",
      desc: "Monitoreamos y gestionamos la superficie de ataque de tu red, identificando puntos vulnerables y protegiendo tus activos digitales ante amenazas externas.",
      icon: "fas fa-shield-alt",
    },
    {
      name: "Backup Management",
      desc: "Gestionamos y aseguramos tus respaldos de datos, garantizando la disponibilidad y recuperación rápida de la información en caso de pérdida o ataque.",
      icon: "fas fa-database",
    },
    {
      name: "Cloud Services",
      desc: "Proporcionamos soluciones de ciberseguridad adaptadas a entornos de nube, protegiendo tus aplicaciones y datos en la nube contra accesos no autorizados.",
      icon: "fas fa-cloud",
    },
    {
      name: "Cybersecurity Assessment",
      desc: " Realizamos evaluaciones de seguridad exhaustivas para identificar vulnerabilidades y riesgos en tus sistemas, proporcionando un informe detallado con acciones correctivas.",
      icon: "fas fa-search",
    },
    {
      name: "Cursos de Cybersecurity EC-Council",
      desc: "Ofrecemos formación oficial y certificada por EC-Council en ciberseguridad, incluyendo cursos como CEH (Certified Ethical Hacker) y otras especializaciones para capacitar a tu equipo en las mejores prácticas de seguridad informática.",
      icon: "fas fa-graduation-cap",
    },
    {
      name: "Patch Management",
      desc: "Realizamos la actualización de parches de seguridad de los diferentes componentes que conforman la infraestructura nuestros clientes, Hyper-V/ESX Hosts (Hypervisor &amp; Management Consoles) y Servidores Windows &amp; Linux tanto en producción como de desarrollo.",
      icon: "fas fa-bug",
    },
    {
      name: "Pentest",
      desc: "Realizamos pruebas de penetración para simular ataques, identificar vulnerabilidades y ayudarte a fortalecer tus defensas antes de que los ciberdelincuentes puedan explotarlas.",
      icon: "fas fa-bug",
    },
    {
      name: "Security Managed Service Provider (SMSP)",
      desc: "Proveemos servicios de seguridad gestionados, monitoreando y gestionando de manera continua la protección de tus sistemas, redes y aplicaciones.",
      icon: "fas fa-tools",
    },
    {
      name: "Training Services",
      desc: "Ofrecemos capacitación en ciberseguridad, ayudando a tu equipo a entender las mejores prácticas y cómo prevenir ataques informáticos.",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      name: "Vulnerability Assessment",
      desc: "Evaluamos de forma sistemática tu infraestructura para identificar, clasificar y priorizar vulnerabilidades de seguridad. Esto permite detectar brechas antes de que sean explotadas, fortaleciendo la protección de tus sistemas y datos críticos.",
      icon: "fas fa-shield-alt",
    },
    {
      name: "Virtualization Infrastructure",
      desc: "Implementamos y gestionamos infraestructuras virtualizadas, proporcionando un entorno más seguro y eficiente para tus sistemas y aplicaciones.",
      icon: "fas fa-exclamation-triangle",
    },
  ];

  return (
    <section
      id="servicios/ciberseguridad"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Servicios
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            ¿ Que Hacemos?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
