import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import * as moment from 'moment';
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    CURSO1: "Essentials_Series",
    CURSO2: "Ethical_Hacking",
    //CURSO3: "Digital_Forensic_Essentials",
    //CURSO2: "Ethical_Hacker_Essentials",
    //CURSO4: "Cloud_Security_Essentials",
    //CURSO5: "SOC_Essentials",
    CURSO6: "Incident_Handling",
    CURSO7: "Computer_Forensics",
    CURSO8: "Cyber_Technician"
  };

  const projectsData = [
    {
      title: "Network Defense Essentials",
      projectInfo:
        "El curso Network Defense Essentials (N|DE) cubre los conceptos fundamentales de seguridad de la información y defensa de redes, proporcionando una visión integral sobre identificación, autenticación, autorización, visualización y más. No se requiere experiencia previa en TI o ciberseguridad para tomar este curso.",
      client: "",
      technologies: "Network Defense Essentials",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/NDE-brochure.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/NDE.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/uEFraQ0cXmY"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO1],
    },
    {
      title: "Ethical Hacking Essentials",
      projectInfo:
        "Ethical Hacking Essentials es un curso introductorio de ciberseguridad que cubre los fundamentos del hacking ético y las pruebas de penetración. Ofrece experiencia práctica en conceptos de seguridad informática y de redes, como amenazas, vulnerabilidades, descifrado de contraseñas, seguridad en aplicaciones web y más. No se requiere experiencia previa en TI o ciberseguridad para este curso.",
      client: "",
      technologies: "Ethical Hacking Essentials",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/EHE-brochure.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/EHE.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/AbRJNZ-B-BM"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO1],
    },
    {
      title: "CERTIFIED SOC ANALYST (C|SA) CERTIFICATION",
      projectInfo:
        "El programa C|SA es un programa de formación y certificación que ayuda al candidato a adquirir habilidades técnicas de vanguardia y demandadas a través de la instrucción de algunos de los entrenadores más experimentados de la industria. El programa se enfoca en crear nuevas oportunidades profesionales mediante un conocimiento exhaustivo y meticuloso, con capacidades avanzadas para contribuir dinámicamente a un equipo SOC. Siendo un programa intensivo de 3 días, cubre a fondo los fundamentos de las operaciones SOC, antes de transmitir conocimientos sobre gestión y correlación de registros, implementación de SIEM, detección avanzada de incidentes y respuesta a incidentes. Además, el candidato aprenderá a gestionar varios procesos SOC y a colaborar con el CSIRT cuando sea necesario.",
      client: "",
      technologies: "Incident Halding",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/CSA-Brochure.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/SOC.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/iU_7zKypJZI"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO6],
    },
    {
      title: "Computer Hacking Forensic Investigator (C|HFI) Program",
      projectInfo:
        "El programa C|HFI de EC-Council prepara a los profesionales de ciberseguridad con los conocimientos y habilidades necesarios para realizar investigaciones forenses digitales efectivas y poner a su organización en un estado de preparación forense. Esto incluye establecer el proceso forense, procedimientos para el manejo de laboratorios y evidencia, así como los procedimientos de investigación necesarios para validar/triagear incidentes y orientar a los equipos de respuesta ante incidentes en la dirección correcta. La preparación forense es crucial, ya que puede marcar la diferencia entre un incidente menor y un ciberataque importante que deje a la empresa al borde del colapso.",
      client: "",
      technologies: "Computer_Forensics",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/CHFIv11.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/DFE.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/xyacpcsilUs"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO7],
    },
    {
      title: " The Certified Cybersecurity Technician",
      projectInfo:
        "El programa Certified Cybersecurity Technician (C|CT) es un curso de ciberseguridad de nivel inicial de EC-Council, los creadores de la certificación Certified Ethical Hacker (C|EH). Está diseñado para mejorar tus habilidades técnicas en múltiples dominios de ciberseguridad, ofreciendo un conocimiento técnico profundo y 85 laboratorios prácticos.",
      client: "",
      technologies: "Cyber Technician",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/CCT-Brochure.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/IoT.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/d9vDS1vHQQE"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO8],
    },
    {
      title: " Certified Ethical Hacker (CEH)",
      projectInfo:
        "De los creadores de Certified Ethical Hacker (CEH) llega la nueva y evolucionada versión 13 con capacidades de inteligencia artificial añadidas. Estructurado en 20 módulos de aprendizaje que cubren más de 550 técnicas de ataque, CEH te proporciona el conocimiento fundamental que necesitas para destacar como profesional de ciberseguridad.",
      client: "",
      technologies: "Ethical Hacking",
      industry: "Ciberseguridad",
      date: `${moment().format('DD/MM/YYYY hh:mm:ss')}`,
      url: {
        name: "Más información",
        link: "images/projects/EC-CEHv13.pdf",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/EHE.jpg",
      sliderImages: [
        "https://www.youtube.com/embed/O8jjNj3hGIQ"
        //"images/projects/project-1.jpg",
        //"images/projects/project-5.jpg",
      ],
      categories: [filters.CURSO2],
    },
    // {
    //   title: "Project Title 2",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-2.jpg",
    //   sliderImages: [
    //     "images/projects/project-2.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.CURSO3],
    // },
    
    // {
    //   title: "Project Title 7",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-7.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.CURSO3, filters.CURSO2],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="cursos/ciberseguridad"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              EC-Council
            </h2>
            <p
              className={
                "text-6 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Todos nuestros cursos son impartidos y certificados por EC-Council, la reconocida entidad en ciberseguridad.
              ¡Capacítate con los mejores, al mejor precio!
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                Todos
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt={project.title}
                          loading="lazy"
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/* <span className="text-light">Categoría</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
